import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import AnkerInfo from '../../components/home/anker-info';
import * as styles from './Life.module.scss';

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const temp = {
      banner: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/beta/_102c70ae4c.jpeg',
      mobileBanner: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/life_banner_mobile_2_f20f852dbc.jpeg',
      section1: {
        content: [
          "<p class='infoTitle'>多元回报和激励体系</p><p class='infoSubTitle fz20' style='margin-bottom: 0.2em;'>基础体系</p><p class='infoContext' style='margin-bottom: 0.8em;'>14-16月基本工资、季度Spot Bonus、业务季度奖、研发项目奖</p><p class='infoSubTitle fz20' style='margin-bottom: 0.2em;'>年度卓越奖</p><p class='infoContext' style='margin-bottom: 0.8em;'>个人奖5-10万，团队奖30-50万<p/><p class='infoSubTitle fz20' style='margin-bottom: 0.2em;'>经营结果分享奖</p><p class='infoContext' style='margin-bottom: 0.8em;'>2023年度，约40%人平均获10-15个月额外奖金</p><p class='infoSubTitle fz20' style='margin-bottom: 0.2em;'>长期股权激励</p><p class='infoContext' style='margin-bottom: 0.8em;'>每年期权&股权激励分配<p/>",
          "<p class='infoTitle'>用机会托底成长<br/>成为卓越的职场精英</p><p class='infoSubTitle fz20'>充分的成长空间</p><p class='infoContext'>提供丰富的业务间轮岗机会</p><p class='infoContext'>海外超10个市场外派需求</p><p class='infoContext'>专家、职能和业务管理三通道发展</p><p class='infoSubTitle fz20'>完善的赋能培训体系</p><p class='infoContext'>新员工第一课/应届生水手计划/管理层卓越经理</p><p class='infoContext'>20 + 专业委员会，牛人领队，互助成长</p><p class='infoContext'>领导力、管理力、创新力，多维度成长的摇篮</p>",
          "<p class='infoTitle'>全方位福利计划为奋斗保驾护航</p><p class='infoSubTitle fz20'>提供三餐和各类生活福利</p><p class='infoContext'>免费早晚餐、午餐补贴，并各类生活福利如生日礼金、内购福利、滴滴企业支付等并提供各类生活福利如生日礼金</p><p class='infoSubTitle fz20'>完善的商业保险和工龄假期计划</p><p class='infoContext'>五险一金、免费体检、百万医疗、商业保险，老员工享带薪假期和旅游津贴</p>",
          "<p class='infoTitle'>丰富的特色文化活动<br/>精彩工作，精彩生活</p><p class='infoSubTitle fz20'>特色产品福利</p><p class='infoContext'>1年2次免费产品福利，终身员工产品折扣</p><p class='infoContext'>联合多个品牌打造员工专属福利</p><p class='infoSubTitle fz20'>丰富的文化活动</p><p class='infoContext'>运动月专题活动、司庆系列活动等福利活动贯穿全年</p><p class='infoContext'>约30个兴趣社团，每年百万团建经费</p>",
        ],
        image: [
          'https://en.anker-in.com/wp-content/uploads/2024/08/life-banner-1.png',
          'https://en.anker-in.com/wp-content/uploads/2024/08/life-banner-2.png',
          'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/life_03_9209005697.jpeg',
          'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/life_04_1ca5e73510.jpeg',
        ],
      },
      seo: {
        title: '安克创新生活.安克创新福利',
        keywords: ['安克创新生活', '安克创新福利', '安克创新薪酬'],
        description: '作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。',
      },
    };
    const {
      pageContext: { datas },
      data,
    } = this.props;
    const copywriting = data.contentfulCopywriting;
    return (
      <div className={styles.home}>
        <img className="pageBanner" src={temp.banner} />
        <img className="mobileBanner" src={temp.mobileBanner} />
        <Layout {...this.props} copywriting={copywriting}>
          <SEO title={temp.seo.title} keywords={temp.seo.keywords} description={temp.seo.description} />
          <div className={'life'}>
            <AnkerInfo temp={temp.section1}></AnkerInfo>
          </div>
        </Layout>
      </div>
    );
  }
}

export default Questions;

export const query = graphql`
query lifeQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        iconfont
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
        children {
          menuId
          name
          url
        }
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    config {
      websites {
        key
        id
        description
      }
    }
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    jobTable {
      last_page
      next_page
      empty_placeholder
    }
  }
} 
`;
